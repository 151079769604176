import React from "react"

import { Divider, Card, CardContent } from "@mui/material"

import CustomList from "../components/CustomList"
import SignificantSubtitle from "../components/SignificantSubtitle"
import Layout from "../components/layout"
import SectionTitle from "../components/SectionTitle"
import Seo from "../components/seo"
import content from "../helpers/content.json"
import CardTitle from "../components/CardTitle"
import { CheckCircleOutlineIcon } from "../helpers/Icons"
import { palette, theme, pageSetting } from "../helpers/theme"
import { capitalFirstLetter } from "../helpers/common"

const Insurance = () => {
  const { title, blocks } = content.ofert.content.insurence
  const subtitle = blocks[0].subContent
  const arr = blocks[1].subContent.split(",")

  return (
    <Layout>
      <Seo title={capitalFirstLetter(title)} />
      <SectionTitle title={title} />
      <div css={styles.root}>
        <SignificantSubtitle
          text={subtitle}
          style={{
            fontSize: "1.2rem",
            marginBottom: theme.gutterBig,
            textAlign: "center",
          }}
        />
        <Card css={styles.card}>
          <CardContent>
            <CardTitle style={styles.title}>{blocks[1].subtitle}</CardTitle>
            <Divider css={{ marginBottom: theme.gutter }} />
            <CustomList
              items={arr}
              icon={
                <CheckCircleOutlineIcon
                  css={{ color: pageSetting.attention }}
                />
              }
            />
          </CardContent>
        </Card>
      </div>
    </Layout>
  )
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontWeight: 700,
    ...pageSetting.text,
    paddingBottom: theme.gutter,
    marginTop: theme.gutter,
    marginBottom: theme.gutter,
  },
  card: {
    maxWidth: 520,
    marginTop: theme.gutterBig,
    textAlign: "center",
    transition: "all .5s ease-out",
    boxShadow: `0 0 5px ${palette.darkGrey}`,
    "&:hover": { transform: "scale3d(1.01, 1.01, 1)" },
  },
}

export default Insurance
